import base from './base';
// 导入接口域名列表
import axios from '../../common/http'; // 导入http中创建的axios实例
let headers = {
        headers: {
            'X-Auth-Token': localStorage.getItem('tokenlo')
        }
}
const article = {
        //多字段查询
        query(params,data={query:{}}){
            let url = `${base.baseUrl}query?`
            Object.keys(params).forEach(
                (item) => (url += `${item}=${params[item]}&`)
            );
            return axios.post(url.substring(0, url.length - 1),data,headers);
        },
        //互联网接口查询
        InternetHospital(params,data={group:yf}){
            let url = `${base.baseUrl}InternetHospital?`
            Object.keys(params).forEach(
                (item) => (url += `${item}=${params[item]}&`)
            );
            return axios.post(url.substring(0, url.length - 1),data,headers);
        },
        //获取 用户角色关联 
        getrole(userrole){
            let url = `${base.baseUrl}getrole?userrole=${userrole}`
            return axios.get(url,headers);
        },
        //查看权限
        userpower(role_id){
            return axios.get(`${base.baseUrl}userpower?role_id=${role_id}`);
        },
        postuserrole(data){
            let url = `${base.baseUrl}getrole?userrole`
            return axios.post(url,data,headers);
        },
        //创建
        created(params,data){
            let url = `${base.baseUrl}created?`
            Object.keys(params).forEach(
                (item) => (url += `${item}=${params[item]}&`)
            );
            return axios.post(url.substring(0, url.length - 1),data);
        },
        //创建用户信息
        usercreated(data){
            let url = `${base.baseUrl}usercreated`
            return axios.post(url,data,headers);
        },
        //添加用户角色
        userrole(data){
            let url = `${base.baseUrl}userrole`
            return axios.post(url,data,headers);
        },
        //删除
        Delete(params,data) {
            let url = `${base.baseUrl}delete?`
            Object.keys(params).forEach(
                (item) => (url += `${item}=${params[item]}&`)
            )
            return axios.put(url.substring(0, url.length - 1),data);
        },
        //修改
        update(params,data) {
            let url = `${base.baseUrl}update?`
            Object.keys(params).forEach(
                (item) => (url += `${item}=${params[item]}&`)
            )
            return axios.put(url.substring(0, url.length - 1),data);
        },
        //多字段查询
        query2(params,data={query:{}}){
            let url = `${base.baseUrl}query2?`
            Object.keys(params).forEach(
                (item) => (url += `${item}=${params[item]}&`)
            );
            return axios.post(url.substring(0, url.length - 1),data,headers);
        },

        //催单
        AccelerationPres(type,data){
            let url = `${base.baseUrl}AccelerationPres?acceleration_type=${type}`
            return axios.post(url,data,headers);
        },
        upload(img) {
            return axios.post(`${base.baseUrl}img?static_path=ComplainChat`,img,app);
        },
        getAdminHelpDrugs(pharmacy_id,start,length) {
            return axios.get(`${base.baseUrl}AdminHelpDrugs?pharmacy_id=${pharmacy_id}&start=${start}&length=${length}`,app);
        },
        postAdminHelpDrugs(pharmacy_id,patient_id,data) {
            return axios.post(`${base.baseUrl}AdminHelpDrugs?pharmacy_id=${pharmacy_id}&patient_id=${patient_id}`,data,app);
        },
        //添加药品
        AddDrugs(data){
            return axios.post(`${base.baseUrl}AddDrugs?`,data,app);
        },
        //解密处方
        aesurl(data){
            return axios.post(`${base.baseUrl}aesurl`,data,app);
        },
         //上传
        uploadPres_imgs(static_path,img) {
            return axios.post(`${base.baseUrl}img?static_path=${static_path}`,img);
        },
        //查询处方患者信息并分组
        getprespatient(pharmacy_id,patient_name,phone,query_type) {
            return axios.get(`${base.baseUrl}getprespatient?pharmacy_id=${pharmacy_id}&patient_name=${patient_name}&phone=${phone}&query_type=${query_type}`);
        },
        // 发送 邀请复诊证明
        userpressms(phone,pharmacy_name,patient_name,work_name) {
            return axios.get(`${base.baseUrl}userpressms?phone=${phone}&pharmacy_name=${pharmacy_name}&patient_name=${patient_name}&work_name=${work_name}`);
        },
        //新老圆心api
        InternetHospitalGet(params,data,ih_type){
            let url = `${base.baseUrl}InternetHospitalGet?ih_type=${ih_type}&`
            Object.keys(params).forEach(
                (item) => (url += `${item}=${params[item]}&`)
            );
            return axios.post(url.substring(0, url.length - 1),data,headers);
        },
        img_info(file_name,check){
            return axios.get(`${base.baseUrl}img_info?file_name=${file_name}&check=${check}`);
        },
        //创建用户账号处方信息
        puurp(data){
            return axios.post(`${base.baseUrl}puurp`,data);
        },
        //创建账号
        superuser(data){
            return axios.post(`${base.baseUrl}superuser`,data);
        },

}

export default article;