<template>
    <div class="bbb">
      <Header/>
      <div id="Interrogation" class="content time_slot">
        <leftNavigationBar/>
        <div class="content_ overview" style="background: #F4F4F4;">
          <el-row :gutter="20">
              <el-col :span="6">
                <div class="el-col-bg">
                  <img class="img" src="../assets/image/01.png" alt="">
                  <div>
                    <p>今日处方</p>
                    <p>{{todayPres}}/单</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
              <div class="el-col-bg">
                <img class="img" src="../assets/image/yesterday.png" alt="">
                <div>
                  <p>昨日此时处方</p>
                  <p>{{yesterday}}/单</p>
                </div>
              </div>
            </el-col>
              <el-col :span="6">
                <div class="el-col-bg">
                  <img class="img" src="../assets/image/02.png" alt="">
                  <div>
                    <p>最近一个月处方</p>
                    <p>{{ThisMonthPres}}/单</p>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="el-col-bg">
                 <img class="img" src="../assets/image/03.png" alt="">
                 <div>
                    <p>累计处方</p>
                    <p>{{dataPres}}/单</p>
                  </div>
                </div>
              </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 50px;">
              <el-col  :span="8">
                <div class="echarts">
                  <div class="echarts_tatle">
                    <div class="table">历史处方对比</div>
            
                  </div>
                  <div id="opinion" style="height: 100%;">

                  </div>
                </div>
              </el-col>
              <el-col  :span="8">
                <div class="echarts">
                  <div class="echarts_tatle">
                    <div class="table">近日处方对比</div>
            
                  </div>
                  <div id="TodayDistribution" style="height: 100%;">

                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <div  class="echarts">
                  <div class="echarts_tatle">
                    <div class="table">上月处方销售情况</div>
                    
                  </div>
                  <div id="UserOpinionEfficient" style="height:100%;">

                  </div>
                </div>
              </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </template>
  <script>
  import leftNavigationBar from './components/leftNavigationBar.vue'
  import Header from '../components/header.vue'
  import mixin from "@/utils/mixin";
  import method from '../assets/js/util.js'
  import * as echarts from 'echarts';
  export default {
    mixins: [mixin],
    components: {
      leftNavigationBar,
      Header,
      
    },
    data() {
      return {
        tableData: [],
        form:{},
        tableData: [],
        activeName:'all',
        opinion:[],
        opinion_value:'',
        yesterday:0,
        UserOpinionEfficient_value:'',
        todayDate:[],
        todayPres:0,//今日处方
        lastMonthDateRange:[],
        ThisMonthPres:0,//本月处方
        dataPres:0,//所有处方
      }
    },
    methods: {
      lastDayOfMonth(){
        var currentDate = new Date();
        // 获取当前年份和月份
        var currentYear = currentDate.getFullYear();
        var currentMonth = currentDate.getMonth() + 1; // 月份从0开始，所以要加1

        // 获取下个月的第一天
        var nextMonthFirstDay = new Date(currentYear, currentMonth, 1);

        // 将下个月的第一天的日期减去一天，即得到当前月的最后一天
        var currentMonthLastDay = new Date(nextMonthFirstDay - 1);

        // 格式化年月日
        var formattedYear = currentMonthLastDay.getFullYear();
        var formattedMonth = (currentMonthLastDay.getMonth() + 1).toString().padStart(2, '0');
        var formattedDay = currentMonthLastDay.getDate().toString().padStart(2, '0');

        // 获取当前年月的最后一天字符串
        var lastDayOfMonth = formattedYear + '-' + formattedMonth + '-' + formattedDay;
        return lastDayOfMonth
      },
      firstDayOfMonthString(){
        var currentDate = new Date();

        // 获取当前年份和月份
        var currentYear = currentDate.getFullYear();
        var currentMonth = currentDate.getMonth() + 1; // 月份从0开始，所以要加1

        // 获取当前月的第一天
        var firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1);

        // 格式化年月日
        var formattedYear = firstDayOfMonth.getFullYear();
        var formattedMonth = (firstDayOfMonth.getMonth() + 1).toString().padStart(2, '0');
        var formattedDay = firstDayOfMonth.getDate().toString().padStart(2, '0');

        // 获取当前年月的第一天字符串
        var firstDayOfMonthString = formattedYear + '-' + formattedMonth + '-' + formattedDay;
        return firstDayOfMonthString
      },
      firstDayOfLastMonthString (){
        // 获取当前日期
        var currentDate = new Date();

        // 获取当前年份和月份
        var currentYear = currentDate.getFullYear();
        var currentMonth = currentDate.getMonth() + 1; // 月份从0开始，所以要加1

        // 计算上个月的年份和月份
        var lastMonthYear = currentMonth === 1 ? currentYear - 1 : currentYear;
        var lastMonth = currentMonth === 1 ? 12 : currentMonth - 1;

        // 获取上个月的第一天
        var firstDayOfLastMonth = new Date(lastMonthYear, lastMonth - 1, 1);

        // 获取这个月的第一天
        var firstDayOfThisMonth = new Date(currentYear, currentMonth - 1, 1);

        // 将这个月的第一天的日期减去一天，即得到上个月的最后一天
        var lastDayOfLastMonth = new Date(firstDayOfThisMonth - 1);

        // 格式化年月日
        var formattedYearFirstDay = firstDayOfLastMonth.getFullYear();
        var formattedMonthFirstDay = (firstDayOfLastMonth.getMonth() + 1).toString().padStart(2, '0');
        var formattedDayFirstDay = firstDayOfLastMonth.getDate().toString().padStart(2, '0');

        var formattedYearLastDay = lastDayOfLastMonth.getFullYear();
        var formattedMonthLastDay = (lastDayOfLastMonth.getMonth() + 1).toString().padStart(2, '0');
        var formattedDayLastDay = lastDayOfLastMonth.getDate().toString().padStart(2, '0');

        // 获取上个月的第一天和最后一天字符串
        var firstDayOfLastMonthString = formattedYearFirstDay + '-' + formattedMonthFirstDay + '-' + formattedDayFirstDay;
        var lastDayOfLastMonthString = formattedYearLastDay + '-' + formattedMonthLastDay + '-' + formattedDayLastDay;
        return [firstDayOfLastMonthString,lastDayOfLastMonthString]
      },
      getDaysInMonth(month ){
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const daysInMonth = new Date(year, month, 0).getDate();
        const dateArray = [];

        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(year, month - 1, day);
            const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
            dateArray.push(formattedDate);
        }

        return dateArray;
      },
      loadList1() {
      let params = {
        search_db:'Pres',
        query_type:'equal'
      }
      let data = {
        query:[
        {
              fild:{pharmacy_id:this.pharmacy_id},
              type:'=',
              condition:'&&'
            },
          {
            fild:{created_at:this.firstDayOfMonthString()},
            type:'>',
            condition:'&&'
          },
          {
            fild:{created_at:this.lastDayOfMonth()},
            type:'<',
            condition:'&&'
          },
          
        ],
        return_table_field:[],
        group_by_date:{
          created_at:'%m-%d'
        }
      }
      this.$api.article.query2(params,data).then(res=>{
        let result = res.data.result
        this.$api.article.query2(
          {
            search_db:'Pres',
            query_type:'equal'
          },
          {
            query:[
            {
              fild:{pharmacy_id:this.pharmacy_id},
              type:'=',
              condition:'&&'
            },
              {
                fild:{created_at:this.firstDayOfLastMonthString()[0]},
                type:'>',
                condition:'&&'
              },
              {
                fild:{created_at:this.firstDayOfLastMonthString()[1]},
                type:'<',
                condition:'&&'
              },
              
            ],
            return_table_field:[],
            group_by_date:{
              created_at:'%m-%d'
            }
          }
        ).then(res=>{
          let date1 = [];
          let data1 = []
          let date2 = [];
          let data2 = []
          //当月
          let theSameMonth = this.getDaysInMonth(this.firstDayOfMonthString().substring(5,7))
          theSameMonth.forEach(element => {
            date2.push(element)
            let item = result.find(item=>{return item._countcreated_at == element})
            if(item){
              data2.push(item._countid)
            }else{
              data2.push(0)
            }
          });
          //上个月
          let lastMonth = this.getDaysInMonth(this.firstDayOfLastMonthString()[0].substring(5,7))
          lastMonth.forEach(element => {
            date1.push(element)
            let item = res.data.result.find(item=>{return item._countcreated_at == element})
            if(item){
              data1.push(item._countid)
            }else{
              data1.push(0)
            }
          });
          var chartDom = document.getElementById('opinion');
          var myChart = echarts.init(chartDom);
          var option;

          const colors = ['#5470C6', '#EE6666'];
          option = {
            color: colors,
            tooltip: {
              trigger: 'none',
              axisPointer: {
                type: 'cross'
              }
            },
            legend: {},
            grid: {
              top: 70,
              bottom: 50
            },
            xAxis: [
              {
                type: 'category',
                axisTick: {
                  alignWithLabel: true
                },
                axisLine: {
                  onZero: false,
                  lineStyle: {
                    color: colors[1]
                  }
                },
                axisPointer: {
                  label: {
                    formatter: function (params) {
                      return (
                        params.value +
                        (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                      );
                    }
                  }
                },
                data: date2
              },
              {
                type: 'category',
                axisTick: {
                  alignWithLabel: true
                },
                axisLine: {
                  onZero: false,
                  lineStyle: {
                    color: colors[0]
                  }
                },
                axisPointer: {
                  label: {
                    formatter: function (params) {
                      return (
                        params.value +
                        (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                      );
                    }
                  }
                },
                data: date1
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: this.firstDayOfLastMonthString()[0].substring(5,7)+'月',
                type: 'line',
                xAxisIndex: 1,
                smooth: true,
                emphasis: {
                  focus: 'series'
                },
                data: data1
              },
              {
                name: this.firstDayOfMonthString().substring(5,7)+'月',
                type: 'line',
                smooth: true,
                emphasis: {
                  focus: 'series'
                },
                data: data2
              }
            ]
          };
          option && myChart.setOption(option);
        })
      })
    },
      loadList(key,params){
        this.$api.article.query2(
            {
              search_db:'Pres',
              query_type:'count',
              order_by:''
            },
            {
              query:[
                {
                  fild:{pharmacy_id:this.pharmacy_id},
                  type:'=',
                  condition:'&&'
                },
                {
                  fild:{created_at:params.start_time},
                  type:'>',
                  condition:'&&'
                },
                {
                  fild:{created_at:params.end_time},
                  type:'<',
                  condition:'&&'
                },
                
              ],
            }
          ).then(res=>{
            this[key] = res.data.result
          })
        // this.$api.article.query(params,{query:{pharmacy_id:this.pharmacy_id}}).then(res => {
        //   this[key] = res.data.count
        // })
      },
      historyPres(){
        let params = {
          search_db:'Pres',
          query_type:'equal'
        }
        let data = {
          query:[
            {
              fild:{pharmacy_id:this.pharmacy_id},
              type:'=',
              condition:'&&'
            },
            {
              fild:{created_at:this.lastMonthDateRange[0]},
              type:'>',
              condition:'&&'
            },
            {
              fild:{created_at:this.lastMonthDateRange[1]},
              type:'<',
              condition:'&&'
            },
            
          ],
          return_table_field:[],
          group_by_date:{
            created_at:'%Y-%m-%d'
          }
        }
        this.$api.article.query2(params,data).then(res=>{
          let dataAxis = []
          let data = [];

          res.data.result.forEach(element => {
            dataAxis.push(element._countcreated_at)
            data.push(element._countid)
          });
          var chartDom = document.getElementById('UserOpinionEfficient');
          var myChart = echarts.init(chartDom);
          var option;
          let yMax = 500;
          let dataShadow = [];
          for (let i = 0; i < data.length; i++) {
            dataShadow.push(yMax);
          }
          option = {
            xAxis: {
              data: dataAxis,
              axisLabel: {
                inside: true,
                color: '#fff'
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              z: 10
            },
            yAxis: {
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                color: '#999'
              }
            },
            dataZoom: [
              {
                type: 'inside'
              }
            ],
            series: [
              {
                type: 'bar',
                showBackground: true,
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#83bff6' },
                    { offset: 0.5, color: '#188df0' },
                    { offset: 1, color: '#188df0' }
                  ])
                },
                emphasis: {
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: '#2378f7' },
                      { offset: 0.7, color: '#2378f7' },
                      { offset: 1, color: '#83bff6' }
                    ])
                  }
                },
                data: data
              }
            ]
          };
          // Enable data zoom when user click bar.
          const zoomSize = 6;
          myChart.on('click', function (params) {
            myChart.dispatchAction({
              type: 'dataZoom',
              startValue: dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
              endValue:
                dataAxis[Math.min(params.dataIndex + zoomSize / 2, data.length - 1)]
            });
          });

          option && myChart.setOption(option);
        })
      },
      getTodayDistribution(){
        var d = new Date().getTime()
        
        let params = {
          search_db:'Pres',
          query_type:'equal'
        }
        let data = {
          query:[
            {
              fild:{pharmacy_id:this.pharmacy_id},
              type:'=',
              condition:'&&'
            },
            {
              fild:{created_at:method.subtractMilliseconds( new Date(),86400000).slice(0,10)+ ' 00:00:01'},
              type:'>',
              condition:'&&'
            },
            {
              fild:{created_at:method.subtractMilliseconds( new Date(),86400000).slice(0,10)+ ' 23:59:59'},
              type:'<',
              condition:'&&'
            },
            
          ],
          return_table_field:[],
          group_by_date:{
            created_at:'%H'
          }
        }
        this.$api.article.query2(params,data).then(res=>{
          console.log(res.data.result)
          let result = res.data.result
          this.$api.article.query2(
            {
              search_db:'Pres',
              query_type:'equal'
            },
            {
              query:[
                {
                  fild:{pharmacy_id:this.pharmacy_id},
                  type:'=',
                  condition:'&&'
                },
                {
                  fild:{created_at:method.formatDate(d).slice(0,10)+ ' 00:00:01'},
                  type:'>',
                  condition:'&&'
                },
                {
                  fild:{created_at:method.formatDate(d).slice(0,10)+ ' 23:59:59'},
                  type:'<',
                  condition:'&&'
                },
                
              ],
              return_table_field:[],
              group_by_date:{
                created_at:'%H'
              }
            }
          ).then(res=>{
            console.log(res.data.result)
            let date1 = [];
            let data1 = []
            let date2 = [];
            let data2 = []
            let date = [
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '07',
                '08',
                '09',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
                '22',
                '23',
                '24',
            ]
            date.forEach(i => {
              date2.push(i)
              let item = result.find(item=>{
                return item._countcreated_at == i
              })
              if(item){
                data2.push(item._countid)
              }else{
                data2.push(0)
              }
            });
            date.forEach(i => {
              date1.push(i)
              let item = res.data.result.find(item=>{
                return item._countcreated_at == i
              })
              if(item){
                data1.push(item._countid)
              }else{
                data1.push(0)
              }
            });
            var chartDom = document.getElementById('TodayDistribution');
            var myChart = echarts.init(chartDom);
            var option;

            const colors = ['#5470C6', '#EE6666'];
            option = {
              color: colors,
              tooltip: {
                trigger: 'none',
                axisPointer: {
                  type: 'cross'
                }
              },
              legend: {},
              grid: {
                top: 70,
                bottom: 50
              },
              xAxis: [
                {
                  type: 'category',
                  axisTick: {
                    alignWithLabel: true
                  },
                  axisLine: {
                    onZero: false,
                    lineStyle: {
                      color: colors[1]
                    }
                  },
                  axisPointer: {
                    label: {
                      formatter: function (params) {
                        return (
                          params.value +
                          (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                        );
                      }
                    }
                  },
                  data: date2
                },
                {
                  type: 'category',
                  axisTick: {
                    alignWithLabel: true
                  },
                  axisLine: {
                    onZero: false,
                    lineStyle: {
                      color: colors[0]
                    }
                  },
                  axisPointer: {
                    label: {
                      formatter: function (params) {
                        return (
                          params.value +
                          (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                        );
                      }
                    }
                  },
                  data: date1
                }
              ],
              yAxis: [
                {
                  type: 'value'
                }
              ],
              series: [
                {
                  name:'今天',
                  type: 'line',
                  xAxisIndex: 1,
                  smooth: true,
                  emphasis: {
                    focus: 'series'
                  },
                  data: data1
                },
                {
                  name: '昨天',
                  type: 'line',
                  smooth: true,
                  emphasis: {
                    focus: 'series'
                  },
                  data: data2
                }
              ]
            };
            option && myChart.setOption(option);
          })
        })
      }
    },
    created() {
      var d = new Date().getTime()
      var currentDate = new Date();
      var oneMonthAgo = new Date();
      oneMonthAgo.setMonth(currentDate.getMonth() - 1);
      var currentDateString = method.formatDate(currentDate.getTime()).slice(0, 10);
      var oneMonthAgoDateString = method.formatDate(oneMonthAgo.getTime()).slice(0, 10);
      this.lastMonthDateRange = [
        oneMonthAgoDateString + ' 00:00:01',  // 近一个月的开始时间
        currentDateString + ' 23:59:59'       // 当前时间的结束时间
      ];
      this.todayDate = [method.formatDate(d).slice(0,10)+ ' 00:00:01',method.formatDate(d).slice(0,10)+' 23:59:59']
      this.$api.article.query(
        {
          search_db:'Pharmacy',
          query_type:'first',
        },
        {
          query:{
            found_id:localStorage.getItem('userrole_id')
          }
        }
      ).then(res=>{
        this.pharmacy_id = res.data.result.id
        this.loadList('todayPres',{search_db:'Pres',query_type:'equal',start_time:this.todayDate[0],end_time:this.todayDate[1]})
        this.loadList('ThisMonthPres',{search_db:'Pres',query_type:'equal',start_time:this.lastMonthDateRange[0],end_time:this.lastMonthDateRange[1]})
        this.loadList('yesterday',{search_db:'Pres',query_type:'count',start_time:method.subtractMilliseconds( new Date(),86400000).slice(0,10)+ ' 00:00:01',end_time:method.subtractMilliseconds( new Date(),86400000)})
        this.loadList('dataPres',{search_db:'Pres',query_type:'equal'})
        this.historyPres()
        this.getTodayDistribution()
        this.loadList1();



      })
    },
    mounted(){
      // this.loadList2();

    }
  }
  </script>
  <style scopde lang="less">
  .overview>div{
    height: auto !important;
  }
   .echarts{
    width: 100%;
    background: #fff;
    border-radius: 6px;
    padding: 20px;
    height: 6rem;
  }
  .echarts_tatle{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
    .el-col-bg{
      background: #fff;
      border-radius: 6px;
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: space-between;
      padding: 0 5%;
      align-items: center;
    }
    .el-col-bg>div{
      font-size: 20px;
      font-weight: 600;

    }
    .el-col-bg>img{
      // height: 100%;
      // width: 30%;
      width: 90px !important;
      height: 90px !important;
      margin-top: -60px;
      height: 30%;
    }
    .table{
      font-size: 20px;
      font-weight: 600;
    }
  </style>