import base from '../module/api/base';
// 导入接口域名列表
import axios from '../common/http'; // 导入http中创建的axios实例

const article = {
        //登录
        login(params) {
            return axios.post(`${base.auth_url}token`, params)
        },
       //获取验证码
        sms(phone){
            return axios.get(`${base.auth_url}sms/${phone}`)
        },
        user(data){
            let url = `${base.auth_url}user`
            return axios.post(url,data);
        }
}

export default article;