
<template>
  <div id="app" >
    <router-view/>
     <footer1/>
     <div id="bg"></div>

  </div>
</template>
<script>
import footer1 from './components/footer.vue'
export default {
  components:{footer1},
  name:'app',
  data() {
    return {
    }


  },
  methods:{

  },
  mounted(){
  
  },
  created(){

  }
}
</script>

<style lang="less">
html,body, body #app{
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  font-size: 16px;
  color:#505d69;
  // background-color: rgba(244, 244, 244, 0.1);
  height: 100%;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#app::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

#app {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
/*#app>div{
  display: flex;
}*/
</style>
