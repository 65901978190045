import axios from '@/api/index'
import module_axios from '@/module/api/index'
import { Message  } from 'element-ui';
import router from '../module/router';
const module_function = {   
   login(UserName,PassWord,url,role){
        let data={
            'auth':{'username': UserName, 'password': PassWord}
        }
        axios.article.login(data).then(res=>{
            if(res && res.data.result.token){
                    localStorage.setItem('userinfo', JSON.stringify(res.data.result))
                    module_axios.article.getrole('user').then(res=>{
                        console.log(res)
                        if(role){  //是否开启权限管理
                            let result = res.data.result.find(item=>{
                                return item.role_name == role
                            })
                            if(result){
                                console.log(1)
                                localStorage.setItem("userrole_id",result.userrole_id);
                                localStorage.setItem("user",JSON.stringify(result));
                                module_axios.article.userpower(result.role_id).then(res=>{
                                    localStorage.setItem("userpower",JSON.stringify(res.data.result));
                                    localStorage.setItem("login_role",role);
                                    router.push(url)
                                })
                            }else{
                                Message({
                                    showClose: true,
                                    message: '账号密码错误',
                                    type: 'error'
                                });
                            }
                            
                        }else{
                            let userRole = res.data.result.filter(item=>{return item.role_name == '药店管理员'} )
                            if(userRole.length !==0){
                                localStorage.setItem("userrole_id",userRole[0].userrole_id);
                                localStorage.setItem("user",JSON.stringify(userRole[0]));
                                let params = {
                                        search_db:'Pharmacy',
                                        query_type:'first',
                                
                                    }
                                    let data = {
                                        query:{
                                          found_id:localStorage.getItem('userrole_id')
                                        }
                                    }
                                    module_axios.article.query(params,data).then(res => {
                                    
                                      console.log(res)
                                      if(res.data.result.id){
                                        localStorage.setItem("pharmacy_id",res.data.result.id);
                                        localStorage.setItem("Pharmacy",JSON.stringify(res.data.result));
                                        router.push(url)

                                      }else{
                                        Message({
                                            showClose: true,
                                            message: '当前账号暂未绑定药店',
                                            type: 'error'
                                        });
                                      }
                                    })
                            }else{
                                Message({
                                    showClose: true,
                                    message: '用户账号未绑定药店',
                                    type: 'error'
                                });
                            }
                            // localStorage.setItem("userrole_id",res.data.result[0].userrole_id);
                            // localStorage.setItem("user",JSON.stringify(res.data.result[0]));
                            // let params = {
                            //     search_db:'Pharmacy',
                            //     query_type:'first',
                          
                            // }
                            // let data = {
                            //     query:{
                            //       found_id:localStorage.getItem('userrole_id')
                            //     }
                            // }
                            // module_axios.article.query(params,data).then(res => {
                             
                            //   console.log(res)
                            //   if(res.data.result.id){
                            //     localStorage.setItem("pharmacy_id",res.data.result.id);
                            //     localStorage.setItem("Pharmacy",JSON.stringify(res.data.result));
                            //     router.push(url)

                            //   }else{
                            //     Message({
                            //         showClose: true,
                            //         message: '当前账号暂未绑定药店',
                            //         type: 'error'
                            //     });
                            //   }
                            // })
                         
                            
                            
                        }
                        
                })
            }else{
                Message({
                    showClose: true,
                    message: '账号密码错误',
                    type: 'error'
                });
            }
        })
   },
    
}


export default module_function;