<template>
    <div class="bbb">
      <Header/>
      <div id="Interrogation" class="content time_slot Patient">
        <leftNavigationBar/>
        <div class="content_">
          <div>
            <div class="search">
            <div>
              <el-button class="refresh" @click="loadList" icon="el-icon-refresh">刷新</el-button>
              <!-- <el-button class="refresh" @click="patient_drawer = true" type="primary" icon="el-icon-plus">添加就诊人</el-button> -->
            </div>
            <div class="roundInp">
                <el-input
                    placeholder="请输入内容"
                    v-model="input">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    <el-button @click="search" slot="append" icon="el-icon-search"></el-button>
                </el-input>
          </div>
          </div>
          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="全部" name="all"></el-tab-pane>
            </el-tabs>
          </div>
          <div>
            <el-table
                :data="tableData"
            >
              <el-table-column
                  prop="name"
                  label="患者姓名"
              >
                <template slot-scope="scope">{{scope.row.Patient.name}}</template>
              </el-table-column>
              <el-table-column
                  label="患者联系方式"
              >
                <template slot-scope="scope">{{scope.row.Patient.phone}}</template>
              </el-table-column>
       
              <el-table-column
                  label="操作"
              >
                <template slot-scope="scope">
                    <el-button type="text" @click="examine(scope.row)">就诊人档案管理</el-button>
                    <el-button type="text" @click="getUserPresCli(scope.row)">邀请补全就诊人档案</el-button>
                    <el-button type="text" @click="getUserPres(scope.row)">查看处方</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          </div>
        </div>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>




        
        <el-drawer
        title="就诊人档案"
        :visible.sync="examine_drawer"
        direction="rtl"
        width="100%"
        :before-close="handleClose">

            <el-button type="primary" style="margin-bottom: 20px;"@click="createdBtn">上传复诊证明</el-button>

            <el-table
            :data="form.Achives"
            style="width: 100%">
                <el-table-column
                    label="当前复诊证明"
                    >
                    <template slot-scope="scope">
                        <el-image 
                            v-for="(item,index) of scope.row.pres_info.imgUrl"
                            style="width: 50px; height: 50px"
                            :src="item" 
                            :preview-src-list="scope.row.pres_info.imgUrl">
                            
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column
                    label="就诊医院"
                    >
                    <template slot-scope="scope">
                        {{ scope.row.pres_info.hospital_name }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="就诊疾病"
                    >
                    <template slot-scope="scope">
                        {{ scope.row.pres_info.patient_disease.join(',') }}
                    </template>
                </el-table-column>
                    <el-table-column
                    label="共享类型"
                >
                    <template slot-scope="scope">
                        {{ scope.row.project_share == project_shareId ? '连锁共享' : '全局共享' }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    >
                    <template slot-scope="scope">
                        <el-button @click="put(scope.row)" type="text">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-drawer>

        <!-- <el-dialog
        title="添加就诊人"
        :visible.sync="patient_drawer"
        width="50%"
        direction="rtl"
        :before-close="files_handleClose">

            <el-form ref="form"  label-position="top" label-width="100px">
                <el-form-item  label="手机号">
                    <el-input v-model="patientForm.phone" placeholder="请输入用户手机号"></el-input>
                </el-form-item>
              <el-form-item >
                <div style="display: flex; justify-content:end ;">
                <el-button round @click="patient_drawer = false">取 消</el-button>
                <el-button type="primary" round @click="puurp">确 认</el-button>
                </div>
            </el-form-item>
            </el-form>
        </el-dialog> -->


        <el-dialog
        :title="`${AchivesTitle}就诊人档案`"
        :visible.sync="files_drawer"
        width="50%"
        direction="rtl"
        :before-close="files_handleClose">

            <el-form ref="form" :model="form" label-position="top" label-width="100px">
              <div style="display: flex;justify-content: space-between;">
                <el-form-item style="width: 49%;" label="当前复诊证明">
                    <el-upload
                    v-if="AchivesTitle == '创建'"
                    class="upload-demo"
                    :action="upload_url"
                    method="post"
                    :on-remove="handleRemove"
                    :on-success="onSuccess"
                    :limit="1"
                    :file-list="pres_imgs"
                    :on-preview="handlePreview"
                    list-type="picture">
                        <el-button size="small" type="primary">上传新的复诊证明</el-button>
                    </el-upload>
                    <el-image
                    v-else
                    style="width: 100%; height:100%"
                    :src="this.pres_imgs[0].url"
                    ></el-image>
                </el-form-item>
                <el-form ref="form" style="width: 49%;" :model="form" label-position="top" label-width="100px">
                    <el-form-item label="首诊医院">
                        <el-input v-model="hospital_name" placeholder="请输入首诊医院"></el-input>
                    </el-form-item>
                    <el-form-item label="确诊疾病">
                        <el-input v-model="patient_drgs" placeholder="基于复诊证明识别，有哪些疾病有复诊证明，非必须"></el-input>
                    </el-form-item>
                    <el-form-item  label="是否连锁共享（开启后您的连锁药店均可以使用就诊人档案）">
                        <el-switch
                        :disabled="AchivesTitle == '编辑'"
                        v-model="project_share"
                        active-text="开启后您的连锁药店均可以使用就诊人档案"
                        >
                        </el-switch>
                    </el-form-item>
                       
                       
                </el-form>
              </div>
              <el-form-item >
                <div style="display: flex; justify-content: space-between;">
                <el-button round @click="files_drawer = false">取 消</el-button>
                <el-button type="primary" round @click="IfCreatedPut">确 认</el-button>
                </div>
            </el-form-item>
            </el-form>
        </el-dialog>



        <el-dialog
        title="邀请补全复诊证明"
        :visible.sync="drawer"
        width="50%"
        direction="rtl"
        :before-close="handleClose">
        <el-form ref="form" :model="form" label-position="top" label-width="100px">
            <el-form-item >
                <el-input
                    disabled
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 8}"
                    placeholder="请输入内容"
                    v-model="txt">
                    </el-input>
            </el-form-item>
            <el-form-item label="患者姓名">
                <el-input v-model="name" @input="txtInput" placeholder="添加发送人姓名，会提高患者提供复诊证明的几率"></el-input>
            </el-form-item>
            <el-form-item label="工作人员姓名">
                <el-input v-model="work_name" @input="txtInput" placeholder="添加发送人姓名，会提高患者提供复诊证明的几率"></el-input>
            </el-form-item>
            <el-form-item >
                <div style="display: flex; justify-content: space-between;">
                <el-button round @click="drawer = false">取 消</el-button>
                <el-button type="primary" round @click="userpressms">邀请补全复诊证明</el-button>
                </div>
            </el-form-item>
        </el-form>
        </el-dialog>
      </div>
    </div>
  </template>
  <script>
  import leftNavigationBar from './components/leftNavigationBar.vue'
  import Header from '../components/header.vue'
  import mixin from "@/utils/mixin";
  import html2canvas from 'html2canvas';
  import base from './api/base.js'
  import method from '../assets/js/util.js'
  export default {
    mixins: [mixin],
    components: {
      leftNavigationBar,
      Header,
      
    },
    data() {
      return {
        patientForm:{
            phone:''
        },
        tableData: [],
        txt:`xx您好，我是xx药店的xx，现在您在我们药店的互联网医院档案还未建立为了方便您后续互联网医保买药，麻烦您方便的时候来我们药店，或者通个下述联系上传下您的线下实体以的复诊证明，上传链接:https://cloud.net-sun.com/#/login?type=Patient`,
        name:'',
        work_name:'',
        patient_drgs:'',
        hospital_name:'',
        project_share:true,
        patient_drawer:false,
        form:{
            Patient:{},
            pres_info:[]
        },
        pres_imgs:[],
        AchivesTitle:'创建',
        examine_drawer:false,
        files_drawer:false,
        project_shareId:'',
        upload_url:'',
        AchivesId:''
      }
    },
    methods: {
        createdBtn(){
            this.AchivesTitle = '创建';
            this.files_drawer = true;
            this.hospital_name = '';
            this.patient_drgs = '';
            this.pres_imgs = []

        },
        userpressms(){
            let phone = this.form.Patient.phone
            let pharmacy_name =  JSON.parse(localStorage.getItem("Pharmacy")).name
            let patient_name = this.name
            let work_name = this.work_name
            this.$api.article.userpressms(phone,pharmacy_name,patient_name,work_name).then(res=>{
                console.log(res)
                if(res.data.code == '200'){
                    this.$message({
                        message: '发送成功',
                        type: 'success'
                    });
                    this.drawer = false
                }
            })
        },
        getUserPresCli(item){
            this.form = item
            this.name = this.form.Patient.name
            this.txt = `${this.name}您好，我是${JSON.parse(localStorage.getItem("Pharmacy")).name}药店的xx，现在您在我们药店的互联网医院档案还未建立为了方便您后续互联网医保买药，麻烦您方便的时候来我们药店，或者通个下述联系上传下您的线下实体以的复诊证明，上传链接:https://cloud.net-sun.com/#/login?type=Patient`
            this.drawer = true
        },
        txtInput(){
            this.txt = `${this.name}您好，我是${JSON.parse(localStorage.getItem("Pharmacy")).name}药店的${this.work_name}，现在您在我们药店的互联网医院档案还未建立为了方便您后续互联网医保买药，麻烦您方便的时候来我们药店，或者通个下述联系上传下您的线下实体以的复诊证明，上传链接:https://cloud.net-sun.com/#/login?type=Patient`
        },
        getUserPres(item){
            this.$router.push({
                name:'Drugs',
                query:{
                    id:item.pres_id
                }
            })
        },
        handleClose(){
            this.examine_drawer = false
            this.drawer = false
        },
        handlePreview(file){
            this.$alert(`<img src="${file.url}" style="width: 100%;">`, '预览图片', {
                dangerouslyUseHTMLString: true,
                showConfirmButton: false,
                customClass: 'image-preview-dialog'
            });
        },
        onSuccess(response, file, fileList){
            this.pres_imgs.push(
                {
                    name:'查看复诊证明',
                    file_name:response.result.file_name,
                    url:`${base.baseUrl}img?static_path=Pres&file_name=${response.result.file_name}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`
                }
            )
            let that = this
            this.loading = this.$loading({
                lock: true,//lock的修改符--默认是false
                text: '就诊信息识别中',    //显示在加载图标下方的加载文案
                color:'rgba(255, 255, 255, 1)',
                spinner: 'el-icon-loading',//自定义加载图标类名
                background: 'rgba(0, 0, 0, 0.9)',//遮罩层颜色
                target: document.querySelector('#table')//loadin覆盖的dom元素节点
            });
            setTimeout(() => {
                that.loading.close();
            }, 6000);
            this.$api.article.img_info(response.result.file_name,true).then(res=>{
                console.log(res)
                that.loading.close();
                if(res.data.result.hospital_name !== '安定镇社区卫生服务'){
                    this.hospital_name = res.data.result.hospital_name
                    this.patient_drgs = res.data.result.patient_disease.join(',')
                }
            })
        },
        put(item){
            this.hospital_name = '';
            this.patient_drgs = '';
            this.pres_imgs = []
            this.AchivesTitle = '编辑'
            this.hospital_name = item.pres_info.hospital_name;
            this.patient_drgs = item.pres_info.patient_disease.join(',')
            this.pres_imgs = [
                {
                    file_name:item.pres_info.imgs[0],
                    name:'查看复诊证明',
                    url:item.pres_info.imgUrl[0],
                }
            ]
            this.AchivesId = item.id
            this.files_drawer = true
        },
        IfCreatedPut(item){
            let pres_info = {
                hospital_name:this.hospital_name,
                patient_disease:this.patient_drgs.split(','),
                medicine:[],
                imgs:[this.pres_imgs[0].file_name]
            }
            if(this.AchivesTitle == '创建'){
                    let params = {
                        search_db: 'Achives',
                    }
                    
                    let data = {
                        created:{
                            name:this.form.Patient.name,
                            project_share:this.project_share ? JSON.parse(localStorage.getItem('Pharmacy')).project_id : '',
                            pres_info:JSON.stringify(pres_info),
                            patient_id:this.form.Patient.patient_id,
                            phrmacy_id:localStorage.getItem('pharmacy_id'),
                            found_id:this.form.Patient.found_id
                        }
                    }
                this.$api.article.created(params,data).then(res=>{
                        this.files_drawer = false
                        this.pres_imgs = []
                        this.hospital_name = ''
                        this.patient_drgs = ''
                        this.update()
                })
            }else{
                let params = {
                    search_db:'Achives',
                    query_type:'first'
                }
                let data = {
                    query:{
                        id:this.AchivesId,
                    },
                    update:{
                        pres_info:JSON.stringify(pres_info)
                    }
                }
                this.$api.article.update(params,data).then(res=>{
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.files_drawer = false
                    this.pres_imgs = []
                    this.hospital_name = ''
                    this.patient_drgs = ''
                    this.update()
              })
            }
           
        },
        handleRemove(file, fileList){
            this.pres_imgs = this.pres_imgs.filter(item=> item.uid !== file.uid)
        },
        examine(item){
        console.log(item)
            let that = this
            this.loading = this.$loading({
                lock: true,//lock的修改符--默认是false
                text: '加载中...',    //显示在加载图标下方的加载文案
                color:'rgba(255, 255, 255, 1)',
                spinner: 'el-icon-loading',//自定义加载图标类名
                background: 'rgba(0, 0, 0, 0.9)',//遮罩层颜色
                target: document.querySelector('#table')//loadin覆盖的dom元素节点
            });
            setTimeout(() => {
                that.loading.close();
            }, 6000);
            let params = {
                search_db: 'Achives',
                query_type: 'equal',
            }
            let data = {
                query:[
                    { condition: '&&', 
                        type: '=', 
                        fild: {
                                patient_id: item.patientId,
                                name:item.Patient.name,
                                
                            }
                    },
                    { condition: '||', 
                        type: '=', 
                        fild: {
                                project_share:[this.project_shareId,'']
                            }
                    },

                ]
            }
            this.$api.article.query2(params,data).then(res=>{
                console.log(res.data.result)
                let Achives = res.data.result
                Achives.forEach(element => {
                    if(element.pres_info !== 'None'){
                        element.pres_info = JSON.parse( element.pres_info)
                        let imgUrl = []
                        element.pres_info.imgs.forEach(item => {
                            imgUrl.push(`${base.baseUrl}img?static_path=Pres&file_name=${item}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`)
                        });
                        element.pres_info['imgUrl'] = imgUrl
                    }
                });
                item['Achives'] = Achives
                this.form = item;
                this.examine_drawer = true
                that.loading.close();
                console.log(this.form)
            })
     
        },
        async loadList(){
            let params = {
                search_db: 'Pres',
                query_type: `limit|${this.start}-${this.pageSize}`,
                order_by: 'desc'
            };
            let data = {
                query: [
                    {
                        fild: { pharmacy_id: localStorage.getItem('pharmacy_id') },
                        type: '=',
                        condition: '&&',
                    },
                ],
            };
            let res = await this.$api.article.query2(params, data);
            
            let Pres = this.arrDistinctByProp(res.data.result,'found_id')
            console.log(Pres)
            Pres.map(item=>{return item['Patient'] = {pres_info:{}}})
            Pres.forEach(element => {
                element.pres_body = JSON.parse(element.pres_body)
                let promise = this.$api.article.query2(
                    {
                        search_db: 'Patient',
                        query_type: 'first',
                    },
                    {
                        query: [
                            { condition: '&&', 
                            type: '=', 
                            fild: {
                                    found_id : element.found_id,
                                    name:element.pres_body.patient.name
                                }
                         }
                        ]
                    }
                ).then(patientRes => {
                    element['Patient'] = patientRes.data.result
                });
            });
            this.tableData = Pres;
            this.total = res.data.total_count * 1;
        },
        async search(){
            if(this.input !==''){
                let params = {
                    search_db: 'Achives',
                    query_type: `limit|${this.start}-${this.pageSize}`,
                };

                let data = {
                    query: [
                    
                    {
                        fild: { name:this.input,project_share:JSON.parse(localStorage.getItem('PharmacyInfo')).project_id },
                        type: '=',
                        condition: '&&',
                    }
                    ],
                };
                // 
                let res = await this.$api.article.query2(params,data)
                res.data.result.map(item=>{return item['Patient'] = {}})
                res.data.result.forEach(element => {
                    let promise = this.$api.article.query2(
                        {
                            search_db: 'Patient',
                            query_type: 'first',
                        },
                        {
                            query: [{ condition: '&&', type: '=', fild: { patient_id: element.patient_id } }]
                        }
                    ).then(patientRes => {
                        if(patientRes.data.result.patient_drgs == 'None'){
                            patientRes.data.result.patient_drgs = ''
                        }
                        if(patientRes.data.result.pres_imgs == 'None'){
                            patientRes.data.result.pres_imgs  = []
                        }else if(res.data.result.id){
                            patientRes.data.result.pres_imgs = JSON.parse(patientRes.data.result.pres_imgs)
                            let pres_imgs = []
                            patientRes.data.result.pres_imgs.forEach(element => {
                                let item = {
                                    name:'查看复诊证明',
                                    file_name:element,
                                    url:`${base.baseUrl}img?static_path=Pres&file_name=${element}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`
                                }
                                pres_imgs.push(item)
                            });
                            patientRes.data.result.pres_imgs = pres_imgs
                        }
                        console.log(patientRes);

                        element['Patient'] = patientRes.data.result
                    });
                });
                this.tableData = res.data.result;
                this.total = res.data.total_count * 1;
                console.log(res)
                // let patient_name = ''
                // let phone = ''
                // if(/^[1]\d{10}$/.test(this.input)){
                //     phone = this.input
                // }else{
                //     patient_name = this.input
                // }
                // let res = await this.$api.article.getprespatient(localStorage.getItem('pharmacy_id'),patient_name,phone,`limit|${this.start}-${this.pageSize}`)
                // res.data.result.map(item=>{return item['Patient'] = {}})
                // res.data.result.forEach(element => {
                //     let promise = this.$api.article.query2(
                //         {
                //             search_db: 'Patient',
                //             query_type: 'first',
                //         },
                //         {
                //             query: [{ condition: '&&', type: '=', fild: { patient_id: element.patientId } }]
                //         }
                //     ).then(patientRes => {
                //         if(patientRes.data.result.patient_drgs == 'None'){
                //             patientRes.data.result.patient_drgs = ''
                //         }
                //         if(patientRes.data.result.pres_imgs == 'None'){
                //             patientRes.data.result.pres_imgs  = []
                //         }else if(res.data.result.id){
                //             patientRes.data.result.pres_imgs = JSON.parse(patientRes.data.result.pres_imgs)
                //             let pres_imgs = []
                //             patientRes.data.result.pres_imgs.forEach(element => {
                //                 let item = {
                //                     name:'查看复诊证明',
                //                     file_name:element,
                //                     url:`${base.baseUrl}img?static_path=Pres&file_name=${element}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`
                //                 }
                //                 pres_imgs.push(item)
                //             });
                //             patientRes.data.result.pres_imgs = pres_imgs
                //         }
                //         console.log(patientRes);

                //         element['Patient'] = patientRes.data.result
                //     });
                // });
                // this.tableData = res.data.result;
                // this.total = res.data.total_count * 1;


            }else{
                this.loadList()
            }
        },
        files_handleClose(){
            this.files_drawer = false
            this.patient_drawer = false
        },
        arrDistinctByProp(arr,prop){
            //只返回第一次出现的，重复出现的过滤掉
            return arr.filter(function(item,index,self){
                return self.findIndex(el=>el[prop]==item[prop])===index
            })
        },
        update(){
            let params = {
                search_db: 'Achives',
                query_type: 'equal',
            }
            let data = {
                query:[
                    { condition: '&&', 
                        type: '=', 
                        fild: {
                                patient_id:this.form.found_id,
                                name:this.form.Patient.name
                            }
                    },
                    { condition: '||', 
                        type: '=', 
                        fild: {
                                project_share:[this.project_shareId,'']
                            }
                    },

                ]
            }
            this.$api.article.query2(params,data).then(res=>{
                let Achives = res.data.result
                Achives.forEach(element => {
                    if(element.pres_info !== 'None'){
                        element.pres_info = JSON.parse( element.pres_info)
                        let imgUrl = []
                        element.pres_info.imgs.forEach(item => {
                            imgUrl.push(`${base.baseUrl}img?static_path=Pres&file_name=${item}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`)
                        });
                        element.pres_info['imgUrl'] = imgUrl
                    }
                });
                let tableData = JSON.parse(JSON.stringify(this.tableData))
                tableData.forEach(element => {
                    if(element.id == this.form.id){
                        element.Achives = Achives
                    }
                });
                this.tableData = tableData
                this.form.Achives = Achives
            })
        },
    },
    created() {
        this.loadList()
        this.upload_url = `${base.baseUrl}img?static_path=Pres&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`
        this.project_shareId = JSON.parse(localStorage.getItem('Pharmacy')).project_id
        
    }
  }
  </script>
  <style scopde lang="less">
    .search button{
        font-size: 15px;
        font-weight: 600;
    }
   
  </style>
  <style>
   .Patient .el-upload-list--picture .el-upload-list__item{
    height: 170px;
    }
   .Patient .el-upload-list--picture .el-upload-list__item-thumbnail{
        height: 150px;
        width: 150px;
    }
    .Patient .el-message-box{
        width: 20rem !important;
        max-height: 100vh;
        overflow-y: scroll !important;
    }
    .el-drawer.rtl{
        width: 60% !important
    }
</style>