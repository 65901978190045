import Vue from 'vue'
import App from './App.vue'
import router from './module/router'
import store from './store'
import axios from '@/api/index'
import module_axios from './module/api/index'
import './assets/css/chat.css'
import css from "./assets/css/common.less"
import './assets/js/rem'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/js/rem'
import "./assets/css/public.css"
import JwChat from 'jwchat';
Vue.use(JwChat)
Vue.prototype.$api_token = axios
Vue.prototype.$api = module_axios

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  css,
  render: h => h(App),
}).$mount('#app')
