<template>
    <div class="bbb">
      <div id="Interrogation" class="content time_slot SelectDrugs">
        <div class="print">
          <h1 class="margin-top title">推荐使用尚云方进行医保报销</h1>
          <el-row :gutter="20" class="margin-top">
            <!-- <el-col :span="12" class="text-align">
                <h1>1：公众号问诊</h1>
                <img class="img" :src="officialAccount" alt="">
            </el-col> -->
            <el-col :span="12" class="text-align">
              <h1>扫码问诊</h1>
                <img class="img" :src="PharmacyUrl" alt="">
            </el-col>
            <el-col :span="12" class="text-align">
              <h1>扫码建档</h1>
                <img class="img" :src="ahiveUrl" alt="">
            </el-col>
          </el-row>
          <h1 class="margin-top buttomtitle">请截图此页面打印后放置于店内</h1>
        </div>
      </div>
    </div>
  </template>
  <script>
  import leftNavigationBar from './components/leftNavigationBar.vue'
  import Header from '../components/header.vue'
  import mixin from "@/utils/mixin";
  import base from './api/base.js'
  import Hls from 'hls.js';
  export default {
    mixins: [mixin],
    components: {
      leftNavigationBar,
      Header,
    },
    data() {
      return {
        RefundQRcode:require("../assets/image/RefundQRcode.png"),
        empowerCode:require("../assets/image/empowerCode.png"),
        officialAccount:require("../assets/image/officialAccount.jpg"),
        PharmacyUrl:'',
        ahiveUrl:''
      }
    },
    methods: {
    },

    created() {
        this.PharmacyUrl = `${base.baseUrl}hospitalQR?pharmacy_id=${localStorage.getItem('pharmacy_id')}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`
        this.ahiveUrl = `${base.baseUrl}hospitalQR?pharmacy_id=${localStorage.getItem('pharmacy_id')}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}&action=achive`
    },
    mounted(){
    }
  }
  </script>
<style scopde lang="less">
.buttomtitle{
    width: 500px;
    font-size: 30px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    right: 50%;
    margin-left: -235px;    /* 宽度的一半 */
    color: #E6A23C;
    
}
.text-align{
    text-align: center
}
.title{
    font-size: 30px;
    text-align: center;
}
.margin-top{
    margin-top: 20px;
   
}
.print{
    width: 1000px;
    background: #fff;
    margin: 0 auto;
    padding: 20px;
    border-radius: 6px;
    // margin-top: 70px;

}
.print img{
    width: 45%;
}
.content_>div{
    height: auto !important;
}
.content_{
    text-align: center;
}
.img{
  width: 300px !important;
  // height: 235px;
}
</style>