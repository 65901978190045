((designWidth, maxWidth) => {
    // noinspection JSAnnotator
    let doc = document
    let win = window
    let docEl = doc.documentElement
    let remStyle = document.createElement('style')
    let tid
  
    function refreshRem () {
      var width = docEl.getBoundingClientRect().width
      maxWidth = maxWidth || 540
      width > maxWidth && (width = maxWidth)
      var rem = width * 100 / designWidth
      remStyle.innerHTML = 'html{font-size:' + rem + 'px;}'
    }
  
    if (docEl.firstElementChild) {
      docEl.firstElementChild.appendChild(remStyle)
    } else {
      var wrap = doc.createElement('div')
      wrap.appendChild(remStyle)
      doc.write(wrap.innerHTML)
      wrap = null
    }
    // 要等 wiewport 设置好后才能执行 refreshRem，不然 refreshRem 会执行2次；
    refreshRem()
  
    win.addEventListener('resize', function () {
      clearTimeout(tid) // 防止执行两次
      tid = setTimeout(refreshRem, 300)
    }, false)
  
    win.addEventListener('pageshow', function (e) {
      if (e.persisted) { // 浏览器后退的时候重新计算
        clearTimeout(tid)
        tid = setTimeout(refreshRem, 300)
      }
    }, false)
  
    if (doc.readyState === 'complete') {
      doc.body.style.fontSize = '16px'
    } else {
      doc.addEventListener('DOMContentLoaded', function (e) {
        doc.body.style.fontSize = '16px'
      }, false)
    }
  })(375, 750)
  // 基准大小
  const baseSize = 32
  // 设置 rem 函数
  function setRem () {
    // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。（简单的说 750 是设计稿的宽度）
    const scale = document.documentElement.clientWidth / 750
    // 设置页面根节点字体大小
    document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
  }
  // 初始化
  setRem()
  // 改变窗口大小时重新设置 rem
  window.onresize = function () {
    setRem()
  }

  