import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
let isLogin = localStorage.getItem('isLogin')
const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    isLogin: isLogin,
    showDatas: {},
    token:'10'
  },
  mutations: {
    addN(state,token){
      state.token=token
    },
    setIsLogin (state, isLogin) {
      state.isLogin = isLogin
      // 将状态存储
      localStorage.setItem('isLogin', isLogin)
    },
    /**
     * 将接受到的消息，全部存储起来，按照不同发送消息人的targetID
     * @param {*} state
     * @param {*} newMsg
     */
    pushData (state, msgContent) {
      // 根据不同的消息id，分别缓存
      const { id, msg } = msgContent
      // 判断是否是第一次接收消息
      if (!state.showDatas[id]) {
        // 创建一个空的数据
        Vue.set(state.showDatas, id, [])
      }
      state.showDatas[id].push(msg)
    }
  },
  actions: {
    setIsLogin: ({ commit }, isLogin) => {
      console.log('触发了actions')
      console.log(isLogin)
      commit('setIsLogin', isLogin)
    }
  },
  getters: {
    isCollapse: state => state.isCollapse,
    isLogin: state => state.isLogin,
    getShowDatas: state => state.showDatas
  }
})

export default store