<template>
    <div class="bbb">
      <div id="Interrogation" class="content time_slot SelectDrugs">
        <div class="content_">
          <el-row :gutter="20">
            <el-col :span="20">
              <h2>视频约15分钟，视频看懂后，玩转问诊统筹</h2>
              <video   ref="myPlayer" style="width:90%;height: calc(100% - 70px);" controls></video>
            </el-col>
            <el-col :span="4" >
              <el-row >
                <el-col :span="24">
                  <h2>手机查看视频</h2>
                  <el-image 
                    style="width: 100%"
                    :src="guideQRcode" 
                    >
                  </el-image>
                </el-col>
              </el-row>
              <el-row  style="position: absolute; bottom: 0;">
                <el-col :span="24" >
                  <h2>产品 Q&A 文档</h2>
                  <el-image 
                    style="width: 100%"
                    :src="documentQRcode" 
                    >
                  </el-image>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </template>
  <script>
  import leftNavigationBar from './components/leftNavigationBar.vue'
  import Header from '../components/header.vue'
  import mixin from "@/utils/mixin";
  import base from './api/base.js'
  import Hls from 'hls.js';
  export default {
    mixins: [mixin],
    components: {
      leftNavigationBar,
      Header,
    },
    data() {
      return {
        guideQRcode:require("../assets/image/guideQRcode.png"),
      documentQRcode:require("../assets/image/documentQRcode.png"),
      }
    },
    methods: {
        loadList(){
          const video = this.$refs.myPlayer;
          console.log(video)
          const hls = new Hls();
          hls.loadSource(`https://cloud.net-sun.com/manual/video/shangyunfangv3.m3u8`);
          hls.attachMedia(video);
          hls.on(Hls.Events.MANIFEST_PARSED, function () {
          });
          if (this.$refs.myPlayer.canPlayType('application/vnd.apple.mpegurl')) {
          // 如果浏览器原生支持播放M3U8文件
          this.$refs.myPlayer.src = `https://cloud.net-sun.com/manual/video/shangyunfangv3.m3u8`
          }
        },

    },

    created() {
     
    },
    mounted(){
        this.loadList()
    }
  }
  </script>
  <style scopde lang="less">

    </style>