<template>
    <div id="footer_">
      药品医疗器械网络信息服务备案：（京）网药械信息备字（2023）第00362号 京ICP备2023022735号-1
    </div>
</template>
<script>
export default {
    data(){
        return{
       
        }
    },
 
 
}
</script>
<style scopde>
#footer_{
    width: 100%;
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    font-size: 12px;
    font-family: Adobe Heiti Std;
    font-weight: 400;
    color: #fff;
    line-height: 35px;
    background: transparent;
    margin: 0 auto;
    /*border-left: 1px solid rgba(0, 0, 0, 0.03);*/
    position: fixed;
    bottom: 0;
  opacity: .22;
  text-align: center;
}
</style>