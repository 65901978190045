const mixin = {
    data() {
        return {
            pageSizes: [10, 20, 30, 40], //可选的分页数量
            start: 0, //开始数据
            pageSize: 10, //每页数量
            total: 0, //分页总数量
            activeName:'all', //tab
            input:'',//搜索
            drawer:false, //控制弹窗
            title:'创建'
        }
    },
    created() {

    },

    methods: {
        //刷新
        refresh(){
            this.loadList();
        },
        //搜索
        search() {
            this.start = 0;
            this.loadList();
        },
        //分页
        handleSizeChange(val) {
            this.pageSize = val;
            this.loadList();
        },
        //分页
        handleCurrentChange(val) {
            this.start = val * 10 - 10
            this.loadList();
        },
        //tab 切换
        handleClick(){
            this.start = 0
            this.total = 0
            this.loadList();
        },
        //删除
        Delete(search_db,id,query_type = 'first'){
            let params = {
                search_db:search_db,
                query_type:query_type
                }
                let data = {
                    query:{
                        id:id
                    },
                    delete:{}
                }
                this.$api.article.Delete(params,data).then(res=>{
                    this.drawer = false
                    if(res){
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                    }
                    
                    this.loadList()
        
                })
        },
        //关闭弹窗
        handleClose(){
            this.drawer = false 
        },
        //请求的二次处理
        apireturn(search_db,query_id,query_value,query_type='first'){
            let params = {
                search_db:search_db,
                query_type:query_type,
            }
            let data = {
                query:{
                  [query_id]:query_value
                }
            }
            return this.$api.article.query(params,data)
        },
        //修改状态
        putstate(search_db,query_key,query_value,update,callback = true){
            let params = {
                search_db:search_db,
                query_type:'first'
            }
            let data = {
                query:{
                    [query_key]:query_value
                },
                update:update
            }
            this.$api.article.update(params,data).then(res=>{
                this.drawer = false
                this.$message({
                    message: '修改成功',
                    type: 'success'
                });
                if(callback){
                    this.loadList()
                }
              })
        },
        //药店 药品id查询替换
        return_drugs_id(){
            // return localStorage.getItem('Pharmacy') == 'None' ? sessionStorage.getItem('pharmacy_id') :localStorage.getItem('drugs_id')
            let Pharmacy = JSON.parse(localStorage.getItem('Pharmacy') )
            return Pharmacy.drugs_id == 'None' ? sessionStorage.getItem('pharmacy_id') : Pharmacy.drugs_id
        },
    }
}
export default mixin;