<template>
    <div class="bbb">
      <div id="Interrogation" class="content time_slot">
        <div class="content_ Mobile " style="background: #F4F4F4;">
            <div class="title">{{Pharmacy.name}}</div>
            <div class="title subtitle">统筹处方管理平台</div>
            <img v-if="show" :src="PharmacyUrl" alt="">
            <p class="text1">
                该二维码将于 {{ time }} 过期
            </p>
            <p class="text">
                请扫描药店二维码
          </p>
          <div  class="Refresh">
            <el-button type="primary" @click="Refresh" icon="el-icon-refresh" >刷新</el-button>
          </div>
          <p>
            
          </p>
        </div>
      </div>
    </div>
  </template>
  <script>
  import mixin from "@/utils/mixin";
  import base from './api/base'
  export default {
    mixins: [mixin],
    components: {

      
    },
    data() {
      return {
        tableData: [],
        time:'',
        Pharmacy:{},
        PharmacyUrl:'',
        show:true
      }
    },
    methods: {
      Refresh(){
        let that = this
        this.show = false
        setTimeout(() => {
          that.show = true
        }, 100);
      },
       addHoursToCurrentTime(hours) {
        var currentDate = new Date();
        var futureDate = new Date(currentDate.getTime() + hours * 60 * 60 * 1000);
        // 获取各个时间组件
        var year = futureDate.getFullYear();
        var month = (futureDate.getMonth() + 1).toString().padStart(2, "0");
        var day = futureDate.getDate().toString().padStart(2, "0");
        var hours = futureDate.getHours().toString().padStart(2, "0");
        var minutes = futureDate.getMinutes().toString().padStart(2, "0");
        var seconds = futureDate.getSeconds().toString().padStart(2, "0");

        // 构建格式化日期时间字符串
        var formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return formattedDateTime;
      },
      loadList(){
        let params = {
            search_db:'Pharmacy',
            query_type:'first',
        }
        let data = {
            query:{
              found_id:localStorage.getItem('userrole_id')
            }
        }
        this.$api.article.query(params,data).then(res => {
          this.Pharmacy = res.data.result
          this.PharmacyUrl = `${base.baseUrl}hospitalQR?pharmacy_id=${res.data.result.id}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&userrole_id=${localStorage.getItem('userrole_id')}`
        })
      }
    },
    created() {
        this.loadList()
        this.time = this.addHoursToCurrentTime(1)
        let that = this
        setInterval(() => {
          that.Refresh()
          that.time = this.addHoursToCurrentTime(1)
        }, 300000);
    },
    mounted(){

    }
  }
  </script>
  <style scopde lang="less">
  .title{
    text-align: center;
    height: auto !important;
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 10px 0;
  }
  .subtitle{
    font-size: 13px;
  }
  .Refresh{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    height: auto !important;
    button{
      width: 90%;
    }
  }
    .Mobile{
        img{
            width: 100%;
        }
    }
    .text{
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
        font-size: 25px;
        text-align: center;
        margin-top: 10px;
        height: auto;
    }
    .text1{
      font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
      font-size: 13px;
      text-align: center;
      margin-top: 10px;
      height: auto;
    }
  </style>