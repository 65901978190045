<template>
  <div class="rediagnosis-certificate">
    <Header/>
    <div class="content">
      <leftNavigationBar/>
      <div class="content_">
        <div class="search">
            <div>
                <el-button class="refresh" @click="loadList" icon="el-icon-refresh">刷新</el-button>
            <el-button class="refresh" @click="drawer = true" type="primary" icon="el-icon-plus">申请连锁搜集档案</el-button>
            </div>
        </div>
        <el-table :data="tableData">
          <el-table-column prop="patient_name" label="患者姓名"></el-table-column>
          <el-table-column prop="cerno" label="身份证号"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column prop="disease" label="疾病">
            <template slot-scope="scope">
                {{ scope.row.disease == 'None' ? '无' : scope.row.disease }}
            </template>
          </el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
                {{ stateKey[scope.row.state] }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
                <el-popconfirm
                    @confirm="Delete('RediagnosisCertificate',scope.row.id)"
                        title="您确定删除此申请吗？"
                    >
                    <el-button type="text" slot="reference">删除</el-button>
                </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="申请连锁搜集档案"  :visible.sync="drawer">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="就诊人姓名" prop="patient_name">
          <el-input v-model="form.patient_name" placeholder="请输入就诊人姓名"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="cerno">
          <el-input v-model="form.cerno" placeholder="请输入身份证号"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="疾病" prop="disease">
          <el-input v-model="form.disease" placeholder="请输入疾病"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="drawer = false">取 消</el-button>
        <el-button type="primary" @click="submitApplication">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import leftNavigationBar from './components/leftNavigationBar.vue'
import Header from '../components/header.vue'
import base from './api/base.js'
import mixin from "@/utils/mixin";

export default {
  mixins: [mixin],
  components: {
    leftNavigationBar,
    Header,
  },
  data() {
    return {
      tableData: [],
      form:{
        patient_name:'',
        cerno:'',
        phone:'',
        disease:''
      },
      stateKey:{
        new:'申请中',
        done:'已完成',
      }
      ,
      rules:{
        patient_name:[{required:true,message:'请输入就诊人姓名',trigger:'blur'}],
        cerno:[{required:true,message:'请输入身份证号',trigger:'blur',pattern:/^\d{17}[\dX]$/,message:'请输入正确的身份证号格式'}],
        phone:[{required:true,message:'请输入手机号',trigger:'blur'},{pattern:/^1[3456789]\d{9}$/,message:'请输入正确的手机号',trigger:'blur'}],
        disease:[{required:true,message:'请输入疾病',trigger:'blur'}]
      }
    }
  },
  methods: {
    loadList() {
      let params = {
        search_db: 'RediagnosisCertificate',
        query_type: `limit|${this.start}-${this.pageSize}`,
        order_by: 'desc'
      };

      let data = {
        query: [
          {
            fild: { pharmacy_id: localStorage.getItem('pharmacy_id') },
            type: '=',
            condition: '&&',
          },
        ],
      };
      this.$api.article.query2(params,data).then(res=>{
        this.tableData = res.data.result
        this.total = res.data.total_count * 1;
      })
    },
    submitApplication(){
        let PharmacyInfo = JSON.parse(localStorage.getItem('Pharmacy'))
        console.log(PharmacyInfo)
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.form)
          let params = {
            search_db:'RediagnosisCertificate',
          }
          let data = {
                created:{
                    pharmacy_id:localStorage.getItem('pharmacy_id'),
                    found_id:localStorage.getItem('userrole_id'),
                    project_id:PharmacyInfo.project_id,
                    ...this.form
                }
            }   
          this.$api.article.created(params,data).then(res=>{
            console.log(res)
            if(res.data.code == '200'){
              this.drawer = false
              this.loadList()
            }else{
              this.$message.error(res.data.text)
            }
          })    
        }
      })
    }
  
  },
  created() {
    this.loadList()
  }
}
</script>

<style scoped>
.search {
  margin-bottom: 20px;
}
</style>
