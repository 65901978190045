<template>
    <div class="bbb">
      <Header/>
      <div id="Interrogation" class="content time_slot">
        <leftNavigationBar/>
        <div class="content_">
          <div>
            <div class="search">
            <div>
              <el-button class="refresh" @click="loadList" icon="el-icon-refresh"></el-button>
              <el-button class="ba2"  icon="el-icon-plus" round @click="create" type="primary">
                添加药品
              </el-button>
            </div>
            <div class="roundInp">
                <el-input
                    placeholder="请输入内容"
                    v-on:input="search"
                    v-model="input">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
          </div>
          </div>
          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="全部" name="all"></el-tab-pane>
              <el-tab-pane label="待新增" name="add"></el-tab-pane>
            </el-tabs>
          </div>
          <div>
            <el-table
                :data="tableData"
            >
              <el-table-column
                  prop="name" 
                  label="名字"
              >
              <template slot-scope="scope">{{scope.row.commonName !== '' ? `${scope.row.name}（${scope.row.commonName}）`:  scope.row.name}}</template>
              </el-table-column>
              <el-table-column
                  prop="specs"
                  label="规格"
              >
              <template slot-scope="scope">{{scope.row.specs == "None" ? scope.row.PublicDrugs[0].specs : scope.row.specs}}</template>
              </el-table-column>
              <el-table-column
                  prop="pkUnit"
                  label="包装单位"
              >
              <template slot-scope="scope">{{scope.row.pkUnit == "None" ? scope.row.PublicDrugs[0].pkUnit : scope.row.pkUnit}}</template>
              </el-table-column>
              <el-table-column
                  prop="drugcode"
                  label="医保编码"
              >
              </el-table-column>
              <el-table-column
                  label="厂商"
              >
              <template slot-scope="scope">
                <span v-if="scope.row.PublicDrugs.length !==0">{{ scope.row.PublicDrugs[0].manufacturingEnterprise }}</span>
              </template>
              </el-table-column>
              <el-table-column
                  label="操作"
              >
                <template slot-scope="scope">
                    <el-popconfirm
                    @confirm="Delete('Drugs',scope.row.id)"
                        title="这是一段内容确定删除吗？"
                    >
                    <el-button type="text" slot="reference">删除</el-button>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
          </div>
        </div>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
        <el-dialog
        :title="title"
        :visible.sync="drawer"
        width="50%"
        direction="rtl"
        :before-close="handleClose">
        <el-form ref="form" :model="form" label-position="top" label-width="100px">
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="药品名字">
                        <el-input v-model="Drugs.name" placeholder="请输入药品名字"></el-input>
                    </el-form-item>
                    <el-form-item label="医保编码">
                        <el-input v-model="Drugs.drugcode" placeholder="请输入医保编码"></el-input>
                    </el-form-item>
                    <el-form-item label="店内码">
                        <el-input v-model="Drugs.extra" placeholder="请输入店内码"></el-input>
                    </el-form-item>
                    
                </el-col>
            </el-row>
            <el-form-item >
            <div style="display: flex; justify-content: space-between;">
              <el-button round @click="drawer = false">取 消</el-button>
              <el-button type="primary" round @click="post_put">{{title == '查看' ? '确定' : title == '创建' ? '创建' : ''}}</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
      </div>
    </div>
  </template>
  <script>
  import leftNavigationBar from './components/leftNavigationBar.vue'
  import Header from '../components/header.vue'
  import mixin from "@/utils/mixin";
  import base from './api/base.js'
  
  export default {
    mixins: [mixin],
    components: {
      leftNavigationBar,
      Header,
    },
    data() {
      return {
        tableData: [],
        Drugs:{
            name:'',
            extra:'',
            drugcode:'',
        },
        form:{
            shelf_state:'1',
            inventory_state:'1',
            maxNumber:1,
            pkUnitId:'',
            uiUsageId:'',
            drugClassId:'',
            brandId:'',
            classId:[],
            imgs:[]
        },
        pintzhong_options:[],
        ypfl:[],//商品单位
        bzdw:[],//包装单位
        imgurl:'',
        yf:[],//用法单位
        Shopstoretype:[],//商城分类
        Brand:[],//品牌分类
      }
    },
    methods: {
        put_state(state,id){
            let params = {
                search_db:'Drugs',
                query_type:'first'
            }
                let data = {
                    update:{
                        state
                    },
                    query:{
                        id
                    }
                }
                this.$api.article.update(params,data).then(res=>{
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.loadList()
                })
        },
        handleRemove(file){
            this.form.imgs.splice(this.form.imgs.indexOf(file.response.result.file_name),1)
            console.log(this.form.imgs)
        },
        handleAvatarSuccess(file){
            console.log(file)
            this.form.imgs.push(file.result.file_name)
        },
        //创建
        create() {  
            this.title = '创建';
            this.drawer = true;
        },
        post_put(){
            let params = {
                search_db:'Drugs',
                query_type:'first',
            }
            let data = {
                query:{
                    pharmacy_id:this.return_drugs_id(),
                    drugcode:this.Drugs.drugcode,
                    
                }
            }
            this.$api.article.query(params,data).then(res=>{
                console.log(res)
                if(res.data.result.id){
                    this.$message('药品库已存在此药品');
                    return
                }
               
                this.$api.article.query(
                    {
                        search_db:'PublicDrugs',
                        query_type:'first',
                    },
                    {
                        query:{
                            drugcode:this.Drugs.drugcode,
                        }
                    }
                ).then(res=>{
                    let PublicDrugs = res.data.result
                    
                    let data = {
                        name:this.Drugs.name,
                        extra:this.Drugs.extra,
                        drugcode:this.Drugs.drugcode,
                        pharmacy_id: this.return_drugs_id(),
                        state:'add',
                        deleted:!PublicDrugs.id ? 1 : PublicDrugs.yx_id.length >5 ? 0 : 1
                    }
                    this.$api.article.AddDrugs(data).then(res=>{
                        console.log(res)
                        if(res){
                            this.$message({
                                message: data.deleted == 0 ? '药品已添加' : '我们已收到该药品的添加申请',
                                type: 'success'
                            });
                            this.Drugs = {
                                name:'',
                                extra:'',
                                drugcode:'',
                            }
                            this.drawer = false
                        }
                    })
                    console.log(res)
                })
                
            
            })
 
        },
        chakan(item){
            this.form = item
            this.title = '查看'
            this.drawer = true
            this.pintzhong_options = [
                {
                    label:`${item.commodity_name} ${item.guige} ${item.suoshu} ${item.pihao}`,
                    value:`${item.commodity_name} ${item.guige} ${item.suoshu} ${item.pihao}`
                }
            ]
            this.form.leibie = '药品'
            this.form.pintzhong = `${item.yaocai} ${item.guige} ${item.suoshu} ${item.pihao}`;
        },
        loadList() {
            let params = {
                search_db:'Pharmacy',
                query_type:'first',
            }
            let data = {
                query:{
                }
            }
            this.$api.article.query(params,data).then(res => {
                this.form['pharmacy_id'] = res.data.result.id 
                let params = {
                    search_db: 'Drugs',
                    query_type: `limit|${this.start}-${this.pageSize}`,
                    deleted:this.activeName == 'add' ? '1' : undefined
                };

                let data = {
                    query: [
                   
                    {
                        fild: { pharmacy_id:this.return_drugs_id() },
                        type: '=',
                        condition: '&&',
                    }
                    ],
                };
                if(this.activeName !== 'all'){
                    data.query.push(
                        {
                        fild: { state: this.activeName, },
                        type: '=',
                        condition: '&&',
                        }
                    )
                }
                if(this.input !== ''){
                    data.query.push(
                        {
                        fild: { name: this.input, },
                        type: '=',
                        condition: '&&',
                        }
                    )
                }

              
                this.$api.article.query2(params,data).then(res => {
                    
                    res.data.result.forEach(element => {
                        element['PublicDrugs'] = [{}]
                        this.$api.article.query(
                            {
                                search_db:'PublicDrugs',
                                query_type:'first'
                            },
                            {
                                query:{
                                    id:element.publicdrugs_id
                                },
                            }
                        ).then(res => {
                            console.log(res)
                            element['PublicDrugs'] = [res.data.result]
                        })
                    });
                    this.tableData = res.data.result;
                    this.total = res.data.total_count*1
                })
                this.apireturn('Pharmacy','found_id',localStorage.getItem('userrole_id')).then(res=>{
                    this.form.pharmacy_id = res.data.result.pharmacy_id 
                })
            })
            
            
        },
        sundry(){
            // //商品分类
            // this.$api.article.InternetHospital({search_db:'Dictionary'},{group:'ypfl'}).then(res => {
            //     this.ypfl = res.data.result
            // })
            // //包装单位
            // this.$api.article.InternetHospital({search_db:'Dictionary'},{group:'bzdw'}).then(res => {
            //     this.bzdw = res.data.result
            // })
            // //用法单位
            // this.$api.article.InternetHospital({search_db:'Dictionary'},{group:'yf'}).then(res => {
            //     this.yf = res.data.result
            // })
            // //商城分类
            // this.$api.article.InternetHospital({search_db:'Shopstoretype'},{'limit': '10', 'page': '1', 'categoryType': ''}).then(res => {
            //     console.log(res.data.result.list)
            //     this.Shopstoretype = res.data.result.list
            // })
            // //品牌
            // this.$api.article.InternetHospital({search_db:'Brand'},{'limit': '100', 'page': '1', 'Type': '1'}).then(res => {
            //     console.log(res.data.result.list)
            //     this.Brand = res.data.result.list
            // })
            // this.$api.article.query({search_db:'PublicDrugs',query_type:'equal'}).then(res => {
            //     console.log(res.data.result)
            // })
        }
    },
    created() {
      this.imgurl = `${base.baseUrl}img?static_path=Drugs&userrole_id=${localStorage.getItem('userrole_id')}&token=${JSON.parse(localStorage.getItem('userinfo')).token}`
      this.loadList();
    //   this.sundry()
    }
  }
  </script>
  <style scopde lang="less">
    .tianjiaclass{
        display: flex;
    }
  
  </style>