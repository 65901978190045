<template>
    <div class="content">
          <img class="logo" src="../assets/image/321.png" alt="">
          <div class="login_content" v-if="!isMobileBg">
              <h2>尙云方统筹开方平台</h2>
              <div class="content_login">
                  <h2>注册</h2>
                  <div class="content_input-right-input">
                    <el-input  prefix-icon="el-icon-first-aid-kit"  v-model="PharmacyName" @keyup.enter.native="submit" clearable placeholder="您的药店名字"></el-input>
                    <el-input  prefix-icon="el-icon-mobile-phone" type="Number"   style="margin-top:8%" v-model="UserName" @keyup.enter.native="submit" clearable placeholder="手机号"></el-input>
                    <el-input prefix-icon=" el-icon-lock" style="margin-top:8%" @keyup.enter.native="submit" v-model="PassWord1" clearable show-password placeholder="请输入密码"></el-input>
                    <el-input prefix-icon=" el-icon-lock" style="margin-top:8%" @keyup.enter.native="submit" v-model="PassWord2" clearable show-password placeholder="请再次输入密码"></el-input>
                    <el-input style="margin-top:8%" maxlength="6" placeholder="请输入验证码" v-model="code" class="input-with-select">
                        <el-button slot="append" @click="getKey" icon="el-icon-key">
                            {{btntable}}
                        </el-button>
                    </el-input>
                    <el-button style="margin-top:8%; width:100%;background: #f8d347; color: #000;height: 60px;"  @click="register">注 册</el-button>
                  </div>
              </div>
          </div>
          <div class="login_content" style="width: 85%;" v-if="isMobileBg">
			<h2>尙云方统筹开方平台</h2>
			<div class="content_login" >
				<h2>注册</h2>
				<div class="content_input-right-input">
                    <el-input  prefix-icon="el-icon-first-aid-kit"  v-model="PharmacyName" @keyup.enter.native="submit" clearable placeholder="您的药店名字"></el-input>
                    <el-input  prefix-icon="el-icon-mobile-phone" type="Number"   style="margin-top:8%" v-model="UserName" @keyup.enter.native="submit" clearable placeholder="手机号"></el-input>
                    <el-input prefix-icon=" el-icon-lock" style="margin-top:8%" @keyup.enter.native="submit" v-model="PassWord1" clearable show-password placeholder="请输入密码"></el-input>
                    <el-input prefix-icon=" el-icon-lock" style="margin-top:8%" @keyup.enter.native="submit" v-model="PassWord2" clearable show-password placeholder="请再次输入密码"></el-input>
                    <el-input style="margin-top:8%" maxlength="6" placeholder="请输入验证码" v-model="code" class="input-with-select">
                        <el-button slot="append" @click="getKey" icon="el-icon-key">
                            {{btntable}}
                        </el-button>
                    </el-input>
                    <el-button style="margin-top:8%; width:100%;background: #f8d347; color: #000;height: 60px;"  @click="register">注 册</el-button>
				</div>
			</div>
			<!-- <div class="footer_">药品医疗器械网络信息服务备案（京）网药械信息备字（2023）第00362号</div> -->
		</div>
      </div>
  </template>
  <script>
  import module_function from '../utils/login'
  export default {
      data() {
      return {
          UserName:'',
          PassWord1:'',
          PassWord2:'',
          PharmacyName:'',
          btntable:'获取验证码',
          isMobileBg:false,
          code:''
      }
      },
      methods:{
        getKey(){
            if(this.UserName == ''){
                this.$message('请输入手机号');
                return
            }
            let that = this
            var total_micro_second = 60;
            let interval = setInterval(function () {
                total_micro_second--;
                that.btntable = total_micro_second+'秒'
                if (total_micro_second <= 0) {
                clearInterval(interval)
                that.btntable = '重新发送'
                }
            }, 1000) 
            if(this.btntable == '重新发送' || this.btntable == '获取验证码'){
                const loading = this.$loading({
                    lock: true,//lock的修改符--默认是false
                    text: '验证码发送中...',//显示在加载图标下方的加载文案
                    color:'rgba(255, 255, 255, 1)',
                    spinner: 'el-icon-loading',//自定义加载图标类名
                    background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
                    target: document.querySelector('#table')//loadin覆盖的dom元素节点
                });
        
                this.$api_token.article.sms(this.UserName).then(res=>{
                    loading.close();
                    this.$message({
                        message: '验证码发送成功!',
                        type: 'success'
                    });
                })
            }
        },
        UserNamePhone(){
            const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
            return regMobile.test(this.UserName)
        },
        register(){
            if(this.PharmacyName == ''){
                this.$message('请输入药店名称');
                return
            }
            if(this.UserName == ''){
                this.$message('请输入手机号');
                return
            }
            if(this.PassWord1 == ''){
                this.$message('请输入密码');
                return
            }
            if(this.PassWord1 !== this.PassWord2){
                this.$message('密码不一致');
                return
            }
            if(this.code == ''){
                this.$message('请输入验证码');
                return
            }
            if(!this.UserNamePhone()){
                this.$message('手机号格式不正确');
                return
            }
           
            let that = this
            this.loading = this.$loading({
                lock: true,//lock的修改符--默认是false
                text: '加载中。。。',    //显示在加载图标下方的加载文案
                color:'rgba(255, 255, 255, 1)',
                spinner: 'el-icon-loading',//自定义加载图标类名
                background: 'rgba(0, 0, 0, 0.9)',//遮罩层颜色
                target: document.querySelector('#table')//loadin覆盖的dom元素节点
            });
            let data = {
                user:{
                    password:this.PassWord1,
                    username:`${this.UserName}`
                },
                code:this.code
            }
            this.$api_token.article.user(data).then(res=>{
                console.log(res)
                if(res){
                    let data={
                        'auth':{'username': this.UserName, 'password': this.PassWord1}
                    }
                    this.$api_token.article.login(data).then(res=>{
                        localStorage.setItem('userinfo', JSON.stringify(res.data.result))
                        this.$api.article.usercreated({user: {name: '',sex: '',age: '',identity: '',address: ''}}).then(res=>{
                                let userRole = {
                                    role:{
                                        role_id:'3c4303b8-894f-4572-8fe2-1118de0c312a',
                                        position:'',
                                        extra:''
                                    }
                                }
                                this.$api.article.userrole(userRole).then(res=>{
                                if(res){
                                    localStorage.setItem("userrole_id",res.data.result.id);
                                    localStorage.setItem("user",JSON.stringify(res.data.result));
                                            let params = {
                                            search_db:'Pharmacy',
                                        }
                                        let data = {
                                            created:{
                                                name:this.PharmacyName,
                                                project_id:'base',
                                                drugs_id:'9fb35c38-fd51-4ffe-805a-d917af2c1698'
                                            }
                                            
                                        }
                                        this.$api.article.created(params,data).then(res=>{
                                            if(res){
                                                this.loading.close();
                                                if(!this.isMobileBg){
                                                    this.$router.push('/overview')
                                                }else{
                                                    this.$router.push('/Mobile')
                                                }
                                                
                                            }
                                        })
                                    // this.$api.article.usercreated(
                                    //     {
                                    //         user: {
                                    //             name: '',
                                    //             sex: '',
                                    //             age: '',
                                    //             identity: '',
                                    //             address: ''
                                    //         }
                                    //     }
                                    // ).then(res=>{
                                    //     localStorage.setItem("user",JSON.stringify(res.data.result));
                                    //         let params = {
                                    //         search_db:'Pharmacy',
                                    //     }
                                    //     let data = {
                                    //         created:{
                                    //             name:this.PharmacyName,
                                    //             project_id:'base',
                                    //             drugs_id:'9fb35c38-fd51-4ffe-805a-d917af2c1698'
                                    //         }
                                            
                                    //     }
                                    //     this.$api.article.created(params,data).then(res=>{
                                    //         if(res){
                                    //             this.loading.close();
                                    //             if(!this.isMobileBg){
                                    //                 this.$router.push('/overview')
                                    //             }else{
                                    //                 this.$router.push('/Mobile')
                                    //             }
                                                
                                    //         }
                                    //     })
                                    // })
                                    
                                }
                            })               
                                        // localStorage.setItem("user",JSON.stringify(res.data.result));
                                        //     let params = {
                                        //     search_db:'Pharmacy',
                                        // }
                                        // let data = {
                                        //     created:{
                                        //         name:this.PharmacyName,
                                        //         project_id:'base',
                                        //         drugs_id:'9fb35c38-fd51-4ffe-805a-d917af2c1698'
                                        //     }
                                            
                                        // }
                                        // this.$api.article.created(params,data).then(res=>{
                                        //     if(res){
                                        //         this.loading.close();
                                        //         if(!this.isMobileBg){
                                        //             this.$router.push('/overview')
                                        //         }else{
                                        //             this.$router.push('/Mobile')
                                        //         }
                                                
                                        //     }
                                        // })
                                    })
                        
                    })
                }
            })
            setTimeout(() => {
                that.loading.close();
            }, 10000);
        },
        isMobile() {
			let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
			return flag;
		},
      },
      created(){
        if(this.isMobile()){
			this.isMobileBg = true
		}
      },
  }
  </script>
  <style lang="less" scoped>
  .logo{
      width: 62px;
      height: 62px;
      position: absolute;
      left: 2%;
      top: 2%;
  }
  .content{
      width: 100%;
      // min-width: 1600px;
      // min-height: 789px;
      height: 100%;
      background-image: url('../assets/image/logonbg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
  }
  .login_content{
      width: 25%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
  }
  .login_content>h2{
      width: 200%;
      position: relative;
      left: -50%;
      color: #fff;
      text-align: center;
      padding: 20px;
      font-weight: 600;
      font-size: 1.8em;
      margin-top: 40%;
      letter-spacing:8px;
  }
  .footer_{
      width: 200%;
      position: fixed;
      color: #fff;
      bottom: 20px;
  }
  .content_login{
      background: #fff;
      border-radius: 6px;
      padding: 20px;
  }
  .content_login>h2{
      padding: 20px;
      padding-left: 0;
      color: #000;
  }
  .content_input_logon{
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      margin-top: 8%;
      padding-bottom: 10%;
  }
  </style>