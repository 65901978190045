<template>
    <div class="bbb">
      <Header/>
      <div id="Interrogation" class="content time_slot SelectDrugs">
        <leftNavigationBar/>
        <div class="content_">
          <div>
            <div class="search">
            <div>
              <el-button class="refresh" @click="loadList" icon="el-icon-refresh">刷新</el-button>
            </div>
            <div class="roundInp">
                <!-- <el-input
                    placeholder="请输入内容"
                    v-on:input="search"
                    v-model="input">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input> -->
          </div>
          </div>
          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="全部" name="all"></el-tab-pane>
            </el-tabs>
          </div>
          <div>
            <el-table
                :data="tableData"
            >
              <el-table-column
                  prop="name"
                  label="当前在店就诊人"
              >
              </el-table-column>
              <el-table-column
                  label="操作"
              >
                <template slot-scope="scope">
                    <el-button type="text" @click="AuxiliaryDrugSelection(scope.row)">辅助选药</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          </div>
        </div>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
        <el-drawer
        title="历史用药"
        :visible.sync="drawer"
        width="50%"
        :before-close="handleClose">
            <div style="display: flex; justify-content: space-between;margin-bottom: 20px;">
                <el-button style="width: 200px;"  type="primary" @click="postDrugSelection">提交</el-button>
                <el-input
                style="width:auto"
                    placeholder="请输入内容"
                    v-on:input="getDrugsList"
                    v-model="search_value">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
            </div>
            <el-table
                    :data="DrugsList"
                >
                <el-table-column
                >
                    <template slot-scope="scope">
                        <el-image
                        v-if="hasParameters(scope.row.imgurl)"
                        style="width: 100px; height: 100px"
                        :src="scope.row.imgurl"
                        ></el-image>
                        <img v-else src="../assets/image/Drugs.webp" style="width: 100px; height: 100px" alt="">
                    </template>
                </el-table-column>
                <el-table-column
                    label="药品名称"
                    prop="name"
                >
            
                </el-table-column>
                <el-table-column
                    label="生产企业"
                >
                    <template slot-scope="scope">
                        {{  scope.row.PublicDrugs.length !==0 ? scope.row.PublicDrugs[0].manufacturingEnterprise : ''}} 
                    </template>
                </el-table-column>
                <el-table-column
                    label="生产企业"
                >
                    <template slot-scope="scope">
                        {{  scope.row.PublicDrugs.length !==0 ? scope.row.PublicDrugs[0].manufacturingEnterprise : ''}} 
                    </template>
                </el-table-column>
                <el-table-column
                    label="国药准字"
                >
                    <template slot-scope="scope">
                        {{  scope.row.PublicDrugs.length !==0 ? scope.row.PublicDrugs[0].approvalNumber : ''}} 
                    </template>
                </el-table-column>
                <el-table-column
                    label=" 医保编码"
                >
                    <template slot-scope="scope">
                        {{  scope.row.PublicDrugs.length !==0 ? scope.row.PublicDrugs[0].drugcode : ''}} 
                    </template>
                </el-table-column>
                <el-table-column
                    label=" 操作"
                >
                    <template slot-scope="scope">
                        <!-- <el-input-number v-model="num"  :min="1" :max="10" label="描述文字"></el-input-number> -->
                        <el-input-number v-model="scope.row.number"  :min="0" :max="scope.row.maxNumber*1" @change="number_change(scope.row,scope.row.index)"  style="width: 150px;"></el-input-number>
                    </template>
                </el-table-column>
            </el-table>
        </el-drawer>
      </div>
    </div>
  </template>
  <script>
  import leftNavigationBar from './components/leftNavigationBar.vue'
  import Header from '../components/header.vue'
  import mixin from "@/utils/mixin";
  import base from './api/base.js'
  export default {
    mixins: [mixin],
    components: {
      leftNavigationBar,
      Header,
      
    },
    data() {
      return {
        tableData:[],
        search_value:'',
        DrugsList:[],
        pharmacy_id:'',
        drugSelected:[],
        patient_id:''
      }
    },
    methods: {
        loadList(){
            this.$api.article.getAdminHelpDrugs(this.pharmacy_id,this.start,this.pageSize).then(res=>{
                console.log(res.data.result)
                this.total = res.data.count*1
                let data = res.data.result
                let list = []
                for(let i in data){
                    list.push(data[i])
                }
                this.tableData = list
                console.log(list)
                // this.drawer = true
                this.getDrugsList()
            })
        },
        AuxiliaryDrugSelection(item){
            this.drawer = true
            this.patient_id = item.patient_id
        },
        async getDrugsList(){
            if(this.search_value !=='' && this.search_value.length<3){
                return
            }
            try {
            let params = {
                search_db: 'Drugs',
                query_type: `limit|0-20`,
                no_count:'1'
            };

            let data = {
                query: [
                {
                    fild: { summary: this.search_value == '' ? undefined : this.search_value },
                    type: this.search_value == '' ? '=' : '%',
                    condition: '&&',
                },
                {
                    fild: { pharmacy_id: this.return_drugs_id() },
                    type: '=',
                    condition: '&&',
                }
                ],
            };

            const drugsRes = await this.$api.article.query2(params, data);

            const drugsPromises = drugsRes.data.result.map(async (element) => {
                element['number'] = '0';
                if (element.maxNumber == 'None') {
                element.maxNumber = '10';
                }

                let PublicDrugsParams = {
                search_db: 'PublicDrugs',
                query_type: 'equal',
                no_count:'1'
                };

                let PublicDrugsData = {
                query: [
                    {
                    fild: { id: element.publicdrugs_id },
                    type: '=',
                    condition: '&&'
                    },
                ],
                };

                const publicDrugsRes = await this.$api.article.query2(PublicDrugsParams, PublicDrugsData);
                console.log(publicDrugsRes);

                element['PublicDrugs'] = publicDrugsRes.data.result;

                if (element.PublicDrugs.length !== 0) {
                element['imgurl'] = `${base.weburl}static/img/${element.PublicDrugs[0].img_url}`;
                } else {
                element['imgurl'] = '';
                }
            });

                await Promise.all(drugsPromises);

                this.DrugsList = drugsRes.data.result;
                console.log(this.DrugsList)
                // console.log(this.tableData)
                // this.backups = JSON.parse(JSON.stringify(drugsRes.data.result));

                // const prescriptionRes = await this.$api.article.query(
                //     {
                //     search_db: 'Pres',
                //     query_type: 'first',
                //     },
                //     {
                //     query: {
                //         found_id: localStorage.getItem('userrole_id'),
                //     },
                //     }
                // );

                // if (prescriptionRes.data.result.medicine && prescriptionRes.data.result.medicine !== 'None') {
                //     let medicine = JSON.parse(prescriptionRes.data.result.medicine);
                //     let drug_id = medicine.map(element => element.skuId);
                //     let LastUsedDrugs = []
                //     drug_id.forEach(element => {
                //     let params = {
                //         search_db: 'Drugs',
                //         query_type: `limit|0-4`,
                //         no_count:'1'
                //     };
                //     let data = {
                //         query: [
                //             {
                //             fild: { publicdrugs_id: element },
                //             type: '=',
                //             condition: '&&',
                //             },
                //             {
                //             fild: { pharmacy_id: prescriptionRes.data.result.pharmacy_id },
                //             type: '=',
                //             condition: '&&',
                //             }
                //         ],
                //         };
                //         this.$api.article.query2(params, data).then(res=>{
                //         console.log(res)
                //         res.data.result.forEach(element => {
                //             LastUsedDrugs.push(element)
                //         });
                //         })
                //     });
                //     this.LastUsedDrugs = LastUsedDrugs
                //     console.log(this.LastUsedDrugs)
                // }
            } catch (error) {
                console.log(error)
            }
        },
        removeZeroValues(array){
            const newArray = array.filter(value => value.number !== 0);
            return newArray;
        },
        number_change(item,index){
        let drugexist =  this.drugSelected.find(element=>{
          return element.id == item.id
        })
        if(drugexist){
          drugexist = item
        }else{
          if(this.drugSelected.length >=5){
            this.$message.error('最多可购买5种药品');
            item.number = 0
          }
          if(item.number !==0){
            this.drugSelected.push(item)
          }
        }
        this.drugSelected = this.removeZeroValues(this.drugSelected)
        console.log(this.drugSelected)
        },
        postDrugSelection(){
            let Drugs = []
            this.drugSelected.forEach(element => {
                if(element.number !== '0' && element.number){
                    element.imgurl = ''
                    Drugs.push(element)
                }
            });
            
            this.$api.article.postAdminHelpDrugs(this.pharmacy_id,this.patient_id,{Drugs}).then(res=>{
                console.log(res)
                if(res){
                    this.drugSelected = []
                    this.patient_id = ''
                    this.drawer = false
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                }
            })
        },
    },
    computed:{
      hasParameters(imageUrl){
        return function (url) {
        return /img\/(.+)/.test(url);
      };
      }
    },
    created() {
        this.pharmacy_id = localStorage.getItem('pharmacy_id')
      this.loadList();
    }
  }
  </script>
  <style scopde lang="less">
    .search button{
        font-size: 15px;
        font-weight: 600;
    }
    
  </style>
  <style>
    .SelectDrugs .el-drawer {
        width: 100% !important;
    }
    </style>